import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import lineSplit from '@turf/line-split';
import booleanIntersects from '@turf/boolean-intersects';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import { GeoJsonLineString } from '@/features/draw/utilities';
import { modes } from '@/features/draw/constants';

const cutLinesMode = Object.assign({}, DrawLineString);

cutLinesMode.onSetup = function (opts) {
  // bind context to "parent" setup
  const onSetup = DrawLineString.onSetup.bind(this, opts);
  const state = onSetup();
  this.map.fire(Constants.events.MODE_CHANGE, {
    mode: modes.DRAW_CUT_LINES,
  });
  state.toCut = opts.toCut;
  return state;
};

cutLinesMode.clickAnywhere = function (state, e) {
  const clickAnywhere = DrawLineString.clickAnywhere.bind(this, state, e);
  clickAnywhere();
  if (state.currentVertexPosition === 2) {
    const lines = this.map.queryRenderedFeatures({
      layers: state.toCut.layers,
      filter: state.toCut.filter,
    });
    const ids = [];
    lines.forEach((f) => {
      if (ids.includes(f.properties.id)) return;
      if (booleanIntersects(f, state.line)) {
        const cutter = GeoJsonLineString(state.line.coordinates, {});
        const split = lineSplit(f, cutter);
        split.features.forEach((s) => {
          const { coordinates } = s.geometry;
          // manually copy user properties
          const properties = {
            id: f.properties.user_id,
            type: f.properties.user_type,
          };
          const fragment = this.newFeature(
            GeoJsonLineString(coordinates, properties),
          );
          this.addFeature(fragment);
          ids.push(f.properties.id);
        });
        this.deleteFeature([f.properties.id], { silent: true });
      }
    });
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

export default cutLinesMode;

import SimpleSelect from '@mapbox/mapbox-gl-draw/src/modes/simple_select';
import { LAYERS } from '@/features/heat-project/constants';
import booleanIntersects from '@turf/boolean-intersects';
import buffer from '@turf/buffer';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import { GeoJsonMultiLineString } from '@/features/draw/utilities';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import mouseEventPoint from '@mapbox/mapbox-gl-draw/src/lib/mouse_event_point';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

const SimpleSelectCustom = Object.assign({}, SimpleSelect);

SimpleSelectCustom.onSetup = function (opts) {
  // turn the opts into state.
  const state = {
    dragMoveLocation: null,
    boxSelectStartLocation: null,
    boxSelectElement: undefined,
    boxSelecting: false,
    canBoxSelect: false,
    dragMoving: false,
    canDragMove: false,
    startWithBoxSelect: opts.startWithBoxSelect || false,
    initiallySelectedFeatureIds: opts.featureIds || [],
  };

  this.setSelected(
    state.initiallySelectedFeatureIds.filter(
      (id) => this.getFeature(id) !== undefined,
    ),
  );
  this.fireActionable();

  this.setActionableState({
    combineFeatures: true,
    uncombineFeatures: true,
    trash: true,
  });

  return state;
};

SimpleSelectCustom.onDrag = function (state, e) {
  if (state.canDragMove) return this.dragMove(state, e);
  if (this.drawConfig.boxSelect && state.canBoxSelect)
    return this.whileBoxSelect(state, e);
};

SimpleSelectCustom.dragMove = function (state, e) {
  // Dragging when drag move is enabled
  state.dragMoving = true;
  e.originalEvent.stopPropagation();

  const delta = {
    lng: e.lngLat.lng - state.dragMoveLocation.lng,
    lat: e.lngLat.lat - state.dragMoveLocation.lat,
  };
  // only move home connection points in order to not fuck up the whole network
  const homeConnectionPoints = this.getSelected().filter(
    (e) => e.properties.type === LAYERS.HOME_CONNECTION_POINT,
  );

  const homeConnectionNetwork = this.map.queryRenderedFeatures({
    layers: [
      'gl-draw-line-deactive.cold',
      'gl-draw-line-active.cold',
      'gl-draw-line-active.hot',
    ],
    filter: ['==', ['get', 'user_type'], LAYERS.HOME_CONNECTION],
  });

  const homeNetworkIds = [];
  // get all related home connection lines and set point state to not connected
  for (const homeConnection of homeConnectionPoints) {
    const { properties } = homeConnection;
    if (properties.isConnected) {
      const connection = homeConnectionNetwork.find(
        (e) => e.properties.user_estate_id === properties.estate_id,
      );
      homeNetworkIds.push(connection.properties.id);
      homeConnection.setProperty('isConnected', false);
    }
  }
  // deselect and delete all related home connection lines
  this.deselect(homeNetworkIds);
  this.deleteFeature(homeNetworkIds);
  moveFeatures(homeConnectionPoints, delta);

  state.dragMoveLocation = e.lngLat;
};

SimpleSelectCustom.onTrash = function () {
  const pointFeatures = [];
  const homeConnectionFeatures = [];
  for (const feature of this._ctx.api.getAll().features) {
    if (feature.properties.type === LAYERS.HOME_CONNECTION) {
      homeConnectionFeatures.push(feature);
    }
    if (
      [LAYERS.HOME_CONNECTION_POINT, LAYERS.HEAT_SOURCE_POINT].includes(
        feature.properties.type,
      )
    ) {
      pointFeatures.push(feature);
    }
  }
  // push all home connection lines to selected in store in order to delete them
  const distNetCoords = [];
  let distNetIsSelected = false;
  for (const selected of this.getSelected()) {
    if (selected.properties.type === LAYERS.DISTRIBUTION_NETWORK) {
      distNetIsSelected = true;
      distNetCoords.push(selected.coordinates);
    }
  }
  if (distNetIsSelected) {
    // add buffer since turf algorithms are not precise enough for
    const distNetBuffered = buffer(
      GeoJsonMultiLineString(distNetCoords),
      0.0001,
    );
    for (const homeConnection of homeConnectionFeatures) {
      if (booleanIntersects(distNetBuffered, homeConnection)) {
        this.select(homeConnection.id);
      }
    }
  }
  for (const selected of this.getSelected()) {
    let type = null;
    let property = null;
    let target_property = null;
    if (selected.properties.type === LAYERS.HOME_CONNECTION) {
      type = LAYERS.HOME_CONNECTION_POINT;
      property = 'estate_id';
      target_property = 'estate_id';
    }
    if (selected.properties.type === LAYERS.HEAT_SOURCE_CONNECTION) {
      type = LAYERS.HEAT_SOURCE_POINT;
      property = 'heat_source_id';
      target_property = 'id';
    }
    if (type !== null) {
      const pointFeature = pointFeatures.find(
        (e) =>
          e.properties.type === type &&
          e.properties[target_property] === selected.properties[property],
      );
      this._ctx.api.setFeatureProperty(pointFeature.id, 'isConnected', false);
    }
    // deselect all building connection point soince they can not be deleted
    if (selected.properties.type === LAYERS.HOME_CONNECTION_POINT) {
      this.deselect(selected.id);
    }
  }
  this.deleteFeature(this.getSelectedIds());
  this.fireActionable();
};

SimpleSelectCustom.onMouseDown = function (state, e) {
  if (CommonSelectors.isActiveFeature(e))
    return this.startOnActiveFeature(state, e);
  if (
    this.drawConfig.boxSelect &&
    (CommonSelectors.isShiftMousedown(e) || state.startWithBoxSelect)
  ) {
    return this.startBoxSelect(state, e);
  }
};

SimpleSelectCustom.onTouchEnd = SimpleSelectCustom.onMouseUp = function (
  state,
  e,
) {
  // End any extended interactions
  if (state.dragMoving) {
    this.fireUpdate();
  } else if (state.boxSelecting) {
    const bbox = [
      state.boxSelectStartLocation,
      mouseEventPoint(e.originalEvent, this.map.getContainer()),
    ];
    const featuresInBox = this.featuresAt(null, bbox, 'click');
    const idsToSelect = this.getUniqueIds(featuresInBox).filter(
      (id) => !this.isSelected(id),
    );

    if (idsToSelect.length) {
      this.select(idsToSelect);
      idsToSelect.forEach((id) => this.doRender(id));
      this.updateUIClasses({ mouse: Constants.cursors.MOVE });
    }
  }
  state.startWithBoxSelect = false;
  this.stopExtendedInteractions(state);
};

export default SimpleSelectCustom;

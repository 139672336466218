export const EconomicEfficiencyTableHeaders = [
  {
    title: 'Name',
    key: 'name',
    align: 'left',
    width: '175px',
  },
  {
    title: 'Szenario',
    key: 'scenario_name',
    width: '175px',
    align: 'left',
  },
  {
    title: 'Erstellt am',
    key: 'created_at',
    width: '175px',
    align: 'left',
  },
  {
    title: 'Projektgebiet',
    key: 'projectArea',
    width: '175px',
    align: 'left',
    sortable: false,
  },
  {
    title: 'Status',
    key: 'progress',
    width: '125px',
    align: 'center',
  },
  {
    title: '',
    key: 'functions',
    width: '100%',
    align: 'end',
    sortable: false,
  },
];

export const AssignmentsHeaders = [
  {
    title: 'Flurstück-ID',
    key: 'estate_key',
    align: 'start',
    width: '150px',
  },
  {
    title: 'Adresse',
    key: 'streets_with_house_number',
    width: '150px',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Objektnummer',
    key: 'provider_id',
    width: '150px',
    align: 'start',
    sortable: false,
  },
  {
    title: 'Verbrauch Fernwärme',
    key: 'yearly',
    width: '200px',
    align: 'end',
    sortable: false,
  },
  {
    title: 'Wohneinheiten',
    key: 'units_residential',
    align: 'end',
    width: '120px',
    sortable: false,
  },
  {
    title: 'Nutzfläche',
    key: 'usable_area_m2',
    align: 'end',
    width: '120px',
  },
  {
    title: 'Anschlussjahr',
    key: 'start_at_year',
    align: 'end',
    width: '130px',
    sortable: false,
  },
];

import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';
import area from '@turf/area';

const measureAreaMode = Object.assign({}, DrawPolygon);

measureAreaMode.clickAnywhere = function (state, e) {
  const clickAnywhere = DrawPolygon.clickAnywhere.bind(this, state, e);
  clickAnywhere();
  const areaSquareMeters = area(state.polygon.toGeoJSON());
  this.map.fire('measure:update', { area: areaSquareMeters });
};

export default measureAreaMode;

<template>
  <div id="app" class="relative bg-infra-background">
    <VProgressLinear
      v-if="getLoadingState"
      indeterminate
      class="absolute top-0"
      style="z-index: 1000"
      :color="loadingColor"
    />
    <!-- Layout component -->

    <RouterView v-slot="{ Component }">
      <Transition :name="transitionName" mode="out-in">
        <Component :is="currentLayout">
          <Component :is="Component" />
        </Component>
      </Transition>
    </RouterView>

    <VSnackbar
      v-model="toast.show"
      :timeout="toast.timeout"
      :color="toast.color"
      :value="true"
      bottom
      :absolute="true"
      right
      outlined
      class="bottom-[78px]"
    >
      <div v-if="toast.title">
        <h4 class="text-white mb-2">{{ toast.title }}</h4>
      </div>
      <div
        class="body-1 flex flex-col gap-1.5"
        :class="toast.textColor ? 'text-' + toast.textColor : 'text-white'"
      >
        <div v-for="(paragraph, index) in toastMessage" :key="index">
          {{ paragraph }}
        </div>
      </div>
      <template v-if="toast.timeout === -1" #actions>
        <VBtn color="white" text @click="toast.show = false">
          <VIcon icon="mdi-close" data-test="close-toast" />
        </VBtn>
      </template>
    </VSnackbar>

    <!-- alert screen size-->
    <AlertScreenSize />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import cookie from 'vue-cookies';
import AlertScreenSize from '@/components/global/AlertScreenSize.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import PageLayout from '@/layouts/PageLayout.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
cookie.set('django_timezone', clientTimeZone);

const store = useStore();
const route = useRoute();
const router = useRouter();

const lastRoutePath = ref('');

router.afterEach((to, from) => {
  lastRoutePath.value = from.path;
});

const toast = computed(() => store.state.layout.toast);
const toastMessage = computed(() =>
  toast.value.message.split('\n\n').map((paragraph) => paragraph.trim()),
);

const getLoadingState = computed(() => store.getters.getLoadingState);

const currentLayout = computed(() => {
  switch (route.meta.layout) {
    case 'Auth':
      return AuthLayout;
    case 'Page':
      return PageLayout;
    default:
      return DefaultLayout;
  }
});

const loadingColor = computed(() => {
  const onAuthPage = currentLayout.value === 'AuthLayout';
  const onLandingPage = route.meta.name === 'landingURL';
  const darkBackground = onAuthPage || onLandingPage;
  return darkBackground ? 'white' : '#001257';
});

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', onResize);
});

function onResize() {
  const height = window.innerHeight;
  store.commit('map/SET_CONTAINER_HEIGHT', height - 170);
}

const transitionName = computed(() => {
  if (
    lastRoutePath.value === '/login' &&
    !['/forgot-password', '/reset-password', '/registration'].includes(
      route.path,
    )
  ) {
    return '';
  }
  return 'fade';
});
</script>

import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import {
  addSnappingVertexLayer,
  clearSnapCoord,
  GeoJsonPoint,
  getSnapToFraction,
} from './utilities';
import { LAYERS } from '@/features/heat-project/constants';
import { SNAP_RADIUS_KM, SET_PROPERTIES } from '@/features/draw/properties';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import { modes } from '@/features/draw/constants';

const snapMode = Object.assign({}, DrawLineString);

snapMode.SNAP_VERTEX_LAYER_ID = '_snap_vertex';
snapMode.TYPE = LAYERS.DISTRIBUTION_NETWORK;

snapMode.onSetup = function (opts) {
  // bind context to "parent" setup
  const onSetup = DrawLineString.onSetup.bind(this, opts);
  const state = onSetup();
  this.map.fire(Constants.events.MODE_CHANGE, {
    mode: modes.DRAW_DISTRIBUTION_NETWORK,
  });
  addSnappingVertexLayer(this.map, this.SNAP_VERTEX_LAYER_ID);
  state.snapPoint = GeoJsonPoint([], {});
  return state;
};

snapMode.clickAnywhere = function (state, e) {
  if (
    (state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition - 1],
      )) ||
    (state.direction === 'backwards' &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition + 1],
      ))
  ) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD });

  let lng = e.lngLat.lng;
  let lat = e.lngLat.lat;

  const snapVertexCoords = state.snapPoint.geometry.coordinates;
  if (snapVertexCoords.length) {
    [lng, lat] = snapVertexCoords;
  }

  state.line.updateCoordinate(state.currentVertexPosition, lng, lat);

  if (state.direction === 'forward') {
    state.currentVertexPosition++;
    state.line.updateCoordinate(state.currentVertexPosition, lng, lat);
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }
};

snapMode.onMouseMove = function (state, e) {
  const mousePointer = {
    type: 'Point',
    coordinates: Object.values(e.lngLat),
  };

  const { line } = state;
  const { snapPoint } = state;

  snapPoint.geometry.coordinates = [];

  const updateCoordinate = (lng, lat) => {
    line.updateCoordinate(state.currentVertexPosition, lng, lat);
    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    }
  };

  const lineFeatures = this.map.queryRenderedFeatures({
    layers: ['gl-draw-line-deactive.cold'],
  });

  const lineToSnap = getSnapToFraction(lineFeatures, line);

  if (lineToSnap.geometry.coordinates.length === 0) {
    updateCoordinate(e.lngLat.lng, e.lngLat.lat);
  } else {
    const snap = nearestPointOnLine(lineToSnap, mousePointer);
    if (
      snap.properties.dist !== Infinity &&
      snap.properties.dist < SNAP_RADIUS_KM.radius
    ) {
      const [lng, lat] = snap.geometry.coordinates;
      updateCoordinate(lng, lat);
      snapPoint.geometry.coordinates = [lng, lat];
    } else {
      updateCoordinate(e.lngLat.lng, e.lngLat.lat);
    }
    this.map.getSource(this.SNAP_VERTEX_LAYER_ID).setData(snapPoint);
  }
};

snapMode.onKeyUp = function (state, e) {
  if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  } else if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

snapMode.onStop = function (state) {
  clearSnapCoord(this.map, this.SNAP_VERTEX_LAYER_ID);
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    for (const [key, value] of Object.entries(SET_PROPERTIES)) {
      state.line.setProperty(key, value);
    }
    state.line.setProperty('type', this.TYPE);
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

snapMode.onTrash = function (state) {
  this.deleteFeature([state.line.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

export default snapMode;

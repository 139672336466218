<template>
  <FormValidator v-model="isFormValid" class="w-[400px]">
    <div class="flex gap-2.5 mt-1">
      <DatePicker
        v-model="localData.created_at"
        :disabled="true"
        label="Erstellungsdatum"
      />
      <DatePicker
        v-model="localData.updated_at"
        :disabled="true"
        label="Zuletzt aktualisiert:"
      />
    </div>
    <InputEl
      v-model="localData.location"
      label="Ort"
      :rules="{ required: true }"
    />
    <InputEl
      v-model="localData.district"
      label="Ortsteil"
      :rules="{ required: true }"
    />
    <InputEl
      v-model="localData.responsible_person"
      label="Verantwortlich(e)"
      :rules="{ required: true }"
    />
    <ButtonEl
      text="Angaben speichern"
      class="w-fit"
      :disabled="!isFormValid"
      @click="save"
    />
  </FormValidator>
</template>

<script setup>
import { ref, watch } from 'vue';
import { axios } from '@/utils/axiosHelper';
import cookies from 'vue-cookies';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import DatePicker from '@/components/storybook/src/stories/datePicker/DatePicker.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';

const props = defineProps({
  projectData: {
    type: Object,
    default: () => ({}),
  },
  premises: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:data']);

// Use computed to create a reactive copy of props.projectData
const localData = ref({ ...props.projectData });
const isFormValid = ref(false);

// Adjusted watcher to compare localData with props.projectData
watch(
  localData,
  (newVal) => {
    emit('update:data', newVal);
  },
  { deep: true },
);

function save() {
  return axios({
    url: `/api/heatprojects/wi-re/wi-re/${localData.value.id}/`,
    method: 'PUT',
    data: localData.value,
    headers: {
      'X-CSRFToken': cookies.get('csrftoken'),
    },
  }).then((response) => {
    localData.value = JSON.parse(JSON.stringify(response.data));
    emit('update:data', localData.value);
  });
}
</script>

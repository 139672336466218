<template>
  <VuetifyDialog
    v-model="dialog"
    :disabled="calculationDisabled"
    title="Neue Berechnung starten"
    :overflow-visible="true"
  >
    <template #activatorItem>
      <ButtonEl
        text="Neue Berechnung starten"
        class="w-fit"
        icon="table_view"
        :disabled="calculationDisabled"
      />
    </template>
    <template #content>
      <div class="flex flex-col gap-2.5 mb-2 py-2">
        <FormValidator v-model="isFormValid">
          <div v-for="(field, index) in createCalculation" :key="index">
            <InputEl
              v-if="field.fieldType === 'input'"
              v-model="field.value"
              :label="field.label"
              :input-type="field.type"
              :rules="field.rules"
              :data-test="'wire-' + field.id"
              :apply-thousands-separator="field.applyThousandsSeparator"
            />
            <DropDown
              v-else-if="field.fieldType === 'dropdown'"
              v-model="field.value"
              :label="field.label"
              items-data-key="name"
              value="id"
              :initial-selection="true"
              :rules="field.rules"
              :items-data="field.data"
              data-test="premises-selection"
            />
          </div>
        </FormValidator>
      </div>
    </template>
    <template #actions>
      <ButtonEl text="Abbrechen" :primary="false" @click="dialog = false" />
      <ButtonEl
        text="Berechnung starten"
        :disabled="!isFormValid"
        data-test="berechnung-starten"
        @click="startCalculation"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import DropDown from '@/components/storybook/src/stories/DropDown/DropDown.vue';

const props = defineProps({
  heatProject: {
    type: Number,
    default: null,
  },
  premises: {
    type: Array,
    default: () => [],
  },
  calculationDisabled: {
    type: Boolean,
    default: false,
  },
  defaultStartYear: {
    type: Number,
    default: 2023,
  },
});

const emit = defineEmits(['created', 'startCalculation']);
const dialog = ref(false);
const isFormValid = ref(false);
const createCalculation = ref([]);

const isValid = computed(() => {
  return createCalculation.value.every((field) => field.isValid);
});

function startCalculation() {
  const requestData = createCalculation.value.reduce((acc, field) => {
    acc[field.id] = field.value;
    return acc;
  }, {});

  const promise = axios({
    url: '/api/heatprojects/wi-re/wi-re/',
    method: 'POST',
    headers: {
      'X-CSRFToken': cookie.get('csrftoken'),
    },
    data: {
      ...requestData,
      heat_project: props.heatProject,
    },
  }).catch((err) => {
    return err;
  });

  emit('created', promise);
  dialog.value = false;
}

function initializeFields() {
  createCalculation.value = [
    {
      label: 'Name',
      id: 'name',
      value: '',
      fieldType: 'input',
      rules: {
        required: true,
      },
    },
    {
      label: 'Geplantes Startjahr',
      id: 'start_at_year',
      value: props.defaultStartYear,
      type: 'integer',
      applyThousandsSeparator: false,
      fieldType: 'input',
      rules: {
        required: true,
        range: [props.defaultStartYear, 2050],
      },
    },
    {
      label: 'Verantwortlich(e)',
      id: 'responsible_person',
      value: '',
      fieldType: 'input',
      rules: {
        required: true,
      },
    },
    {
      label: 'Prämissenset',
      id: 'wire_premises',
      value: null,
      fieldType: 'dropdown',
      data: props.premises,
      rules: {
        required: true,
      },
    },
  ];
}

// Initialize fields especially necessary for resetting defaultStartYear
// defaultStartYear value depends on the base year of the current scenario
initializeFields();
watch(dialog, (newDialog) => {
  if (newDialog) {
    initializeFields();
  }
});
</script>

import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import FreehandMode from 'mapbox-gl-draw-freehand-mode';

const FeaturesWithin = Object.assign({}, FreehandMode);

FeaturesWithin.onSetup = function (opts) {
  const onSetup = FreehandMode.onSetup.bind(this);
  const state = onSetup();
  this.map.fire(Constants.events.MODE_CHANGE, {
    mode: opts.mode,
  });
  state.polygon.setProperty('type', opts.actionType);
  return state;
};

FeaturesWithin.onKeyUp = function (state, e) {
  if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.polygon.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  } else if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    this.deleteFeature([state.polygon.id], { silent: true });
  }
};

FeaturesWithin.onMouseUp = function (state, e) {
  if (state.dragMoving) {
    this.simplify(state.polygon);
    this.fireUpdate();
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    this.deleteFeature([state.polygon.id], { silent: true });
  }
};

FeaturesWithin.clickAnywhere = function (state, e) {
  if (
    state.currentVertexPosition > 0 &&
    isEventAtCoordinates(
      e,
      state.polygon.coordinates[0][state.currentVertexPosition - 1],
    )
  ) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.polygon.id],
    });
    this.deleteFeature([state.polygon.id], { silent: true });
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat,
  );
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(
    `0.${state.currentVertexPosition}`,
    e.lngLat.lng,
    e.lngLat.lat,
  );
};

FeaturesWithin.clickOnVertex = function (state) {
  this.changeMode(Constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
  this.deleteFeature([state.polygon.id], { silent: true });
};

FeaturesWithin.clickOnVertex = function (state) {
  this.changeMode(Constants.modes.SIMPLE_SELECT, {
    featureIds: [state.polygon.id],
  });
  this.deleteFeature([state.polygon.id], { silent: true });
};

export default FeaturesWithin;

import { useModuleStore } from '@/configs/module-store';

export default {
  SET_LOGOUT: (state) => {
    state.user = '';
  },
  SET_PROFILE_DATA: (state, resp) => {
    state.user = resp.user;

    const moduleStore = useModuleStore();
    moduleStore.updateModules(resp.active_modules);
  },
};

<template>
  <div class="flex flex-col gap-5 pt-3.5 w-full">
    <div class="flex justify-between">
      <div class="flex items-center">
        <ButtonEl
          color="color2"
          icon="arrow_back"
          icon-fill="text-inverted-color2"
          class="mr-5"
          @click="$emit('goBack')"
        />
        <h3 class="text-title-color2">{{ projectData.name }}</h3>
      </div>
      <div class="flex justify-between gap-3 pb-5 pt-3.5">
        <!-- project info-->
        <div class="flex gap-5 items-center">
          <div class="info-button subtitle-1">
            {{
              new Date(projectData.created_at).toLocaleDateString('de-DE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
            }}
          </div>
          <div class="info-button subtitle-1">
            {{ projectData.scenario_name }}
          </div>
        </div>
      </div>
    </div>
    <!--tabs-->
    <v-card elevation="0" class="mt-3">
      <div class="border-b-2">
        <v-tabs v-model="tab" class="mb-[-2px]" density="compact">
          <v-tab
            v-for="header in tabData"
            :key="header"
            class="tab-style-custom"
            :value="header"
            :ripple="false"
            ><h4>{{ header }}</h4></v-tab
          >
        </v-tabs>
      </div>
      <v-card-text class="px-0">
        <v-window v-model="tab">
          <TabWireAllgemeineAngaben
            v-if="tab === 'Allgemeine Angaben'"
            :project-data="localProjectData"
            :premises="props.premises"
            @update:data="localProjectData = $event"
          />
          <TabWireAnschlussInfos
            v-else-if="tab === 'Anschlussinformationen'"
            :project-data="localProjectData"
            :default-start-year="defaultStartYear"
            @update:data="localProjectData = $event"
            @trigger-calculation="updateCalculation"
          />
          <TabWireAnschlussadressen
            v-else-if="assignmentOptions && tab === 'Anschlussadressen'"
            :project-data="projectData"
            :options="assignmentOptions"
            :default-start-year
            @trigger-calculation="updateCalculation"
          />
          <WiReTableExpandable
            v-else-if="tab === 'Auswertung'"
            :project-data="projectData"
          />
          <TabWireKalkulation
            v-else-if="tab === 'Kalkulation'"
            :project-data="projectData"
          />
        </v-window>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { axios } from '@/utils/axiosHelper';
import cookie from 'vue-cookies';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import TabWireAllgemeineAngaben from '@/apps/economic-efficiency/edit-tab-items/TabWireAllgemeineAngaben.vue';
import TabWireAnschlussInfos from '@/apps/economic-efficiency/edit-tab-items/TabWireAnschlussInfos.vue';
import TabWireAnschlussadressen from '@/apps/economic-efficiency/edit-tab-items/anschluss-adressen/TabWireAnschlussadressen.vue';
import WiReTableExpandable from '@/apps/economic-efficiency/WiReTableExpandable.vue';
import TabWireKalkulation from '@/apps/economic-efficiency/edit-tab-items/TabWireKalkulation.vue';

const emit = defineEmits(['goBack', 'update:projectData']);

const props = defineProps({
  projectData: {
    type: Object,
    default: () => {},
  },
  defaultStartYear: {
    type: Number,
    default: 2023,
  },
});

const tab = ref('Anschlussinformationen');
const tabData = [
  'Allgemeine Angaben',
  'Anschlussinformationen',
  'Anschlussadressen',
  'Auswertung',
  'Kalkulation',
];
const localProjectData = ref({ ...props.projectData });
const assignmentOptions = ref();

onMounted(() => {
  getAssignmentOptions();
});

function getAssignmentOptions() {
  return axios({
    url: '/api/heatprojects/wi-re/estateconnections/',
    method: 'OPTIONS',
  }).then((response) => {
    assignmentOptions.value = response.data.actions.POST;
  });
}

function updateCalculation() {
  const promise = axios({
    url: `/api/heatprojects/wi-re/wi-re/${props.projectData.id}/`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': cookie.get('csrftoken'),
    },
    data: {
      ...localProjectData.value,
    },
  }).catch((err) => {
    return err;
  });
  emit('update:projectData', promise);
}
</script>

<style lang="scss" scoped>
.info-button {
  @apply bg-subtle text-title-color2 p-2.5 rounded-[8px];
}

.tab-style-custom {
  @apply first:ml-0 px-2 normal-case text-[18px] ml-4 tracking-normal;
}

.v-window,
.v-card {
  overflow: visible;
}

.v-tab {
  min-width: unset !important;

  h4 {
    @apply text-title-neutral hover:text-title-color1;
  }

  &.v-slide-group-item--active h4 {
    @apply text-title-color1;
  }
}

.v-tab--selected {
  @apply text-title-color1;
}
</style>

<template>
  <div class="flex flex-col py-4">
    <h5 class="text-title-color2 pt-2 mb-2.5">
      Sensitivität Trassenpreise bzw. Absatzmengen
    </h5>
    <div>
      <div
        v-for="(item, index) in expandedRowData"
        :key="index"
        class="flex items-center justify-between gap-1.5 p-2 bg-default text-color2"
      >
        <div class="flex">
          <Component :is="item.icon" class="w-4 h-4" />
          <div class="body-3">
            {{ filteredOptions[item.key].label }}
          </div>
        </div>
        <div
          class="subtitle-2 flex cursor-pointer"
          @click="openExpandedDialog(data.item, item)"
        >
          <EditPenIconRight
            v-if="showSubsidyInput(item.key)"
            class="w-3 mr-1.5 opacity-40 hover:opacity-100 hover:text-black-500 cursor-pointer"
          />
          {{ calculateAndFormatValue(item, data) }}
          {{ item.unit }}
        </div>
      </div>
    </div>
  </div>
  <VuetifyDialog
    v-if="expandedItemActive && inputValues"
    v-model="expandedFieldDialog"
    :title="filteredOptions[expandedItemActive].label"
  >
    <template #content>
      <div class="h-20">
        <FormValidator v-model="isFormValid">
          <InputEl
            v-model="inputValues[expandedItemActive]"
            input-type="integer"
            :placeholder="`Bitte ${filteredOptions[expandedItemActive].label} eingeben`"
            :label="filteredOptions[expandedItemActive].label"
            :suffix="
              expandedRowData.find((item) => item.key === expandedItemActive)
                .unit
            "
            :rules="{ required: true }"
          />
        </FormValidator>
      </div>
    </template>
    <template #actions>
      <ButtonEl
        variant="secondary"
        text="Abbrechen"
        @click="expandedFieldDialog = false"
      />
      <ButtonEl
        text="Speichern"
        :disabled="!isFormValid"
        @click="saveExpandedField(data.item, expandedItemActive)"
      />
    </template>
  </VuetifyDialog>
</template>

<script setup>
import { computed, ref } from 'vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import InputEl from '@/components/storybook/src/stories/input/InputEl.vue';
import VuetifyDialog from '@/components/storybook/src/stories/vuetifyDialog/VuetifyDialog.vue';
import FormValidator from '@/components/storybook/src/stories/FormValidator/FormValidator.vue';
import EditPenIconRight from '@/components/storybook/src/assets/icons/EditPenIconRight.vue';
import cloneObject from '@/utils/objectUtils';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  options: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['saveExpandedField']);

const expandedRowData = ref([
  {
    key: 'specific_heat_demand_kwh_m2',
    icon: 'HeatWaveIcon',
    unit: 'kWh/m²/a',
  },
  {
    key: 'construction_cost_subsidy',
    icon: 'EuroShape',
    unit: '€',
  },
  {
    key: 'building_connection_cost_subsidy',
    icon: 'EuroShape',
    unit: '€',
  },
]);
const expandedFieldDialog = ref(false);
const inputValues = ref(null);
const expandedItemActive = ref(null);
const isFormValid = ref(true);

const filteredOptions = computed(() => {
  if (!props.options) {
    return {};
  }

  const keysToInclude = expandedRowData.value.map((item) => item.key);

  return Object.keys(props.options)
    .filter((key) => keysToInclude.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: props.options[key] }), {});
});

function openExpandedDialog(data, item) {
  inputValues.value = cloneObject(data);
  expandedFieldDialog.value = true;
  expandedItemActive.value = item.key;
}

function saveExpandedField(estate, fieldName) {
  expandedFieldDialog.value = false;
  estate[fieldName] = inputValues.value[fieldName];
  inputValues.value = null;
  emit('saveExpandedField', { estate, fieldName });
}

function calculateAndFormatValue(item, data) {
  const baseValue = data.item[item.key];
  const multiplier = item.multiplier || 1;
  const result = baseValue * multiplier;

  return result.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

function showSubsidyInput(key) {
  return [
    'construction_cost_subsidy',
    'building_connection_cost_subsidy',
  ].includes(key);
}
</script>

import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import length from '@turf/length';

const measurePointsMode = Object.assign({}, DrawLineString);

function getLengthInMeters(feature) {
  return length(feature, { units: 'kilometers' }) * 1000;
}

measurePointsMode.clickAnywhere = function (state, e) {
  const clickAnywhere = DrawLineString.clickAnywhere.bind(this, state, e);
  clickAnywhere();
  if (state.line.coordinates.length > 1) {
    const distance = getLengthInMeters(state.line.toGeoJSON());
    this.map.fire('measure:update', { distance });
  }
};

export default measurePointsMode;

import DrawLineString from '@mapbox/mapbox-gl-draw/src/modes/draw_line_string';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import nearestPoint from '@turf/nearest-point';
import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';

import {
  addSnappingVertexLayer,
  clearSnapCoord,
  GeoJsonPoint,
  getSnapToFraction,
} from './utilities';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import { SNAP_RADIUS_KM, SET_PROPERTIES } from '@/features/draw/properties';

const homeConnectionMode = Object.assign({}, DrawLineString);

homeConnectionMode.SNAP_VERTEX_LAYER_ID = '_snap_vertex';

/**
 * connect and add point to project.
 * In key from the point layer with filter can be defined
 * @param  {Object: {from: {layers: Array.<String>, filter: Array, copyProp: String, setProp: Object}}} opts start from point
 * optionally copy prop from point to line and set prop of connected point
 */
homeConnectionMode.onSetup = function (opts) {
  // bind context to "parent" setup
  const onSetup = DrawLineString.onSetup.bind(this, opts);
  const state = onSetup();
  addSnappingVertexLayer(this.map, this.SNAP_VERTEX_LAYER_ID);
  this.map.fire(Constants.events.MODE_CHANGE, {
    mode: opts.mode,
  });
  state.snapPoint = GeoJsonPoint([], {});
  state.setType = opts.setType;
  state.connectFrom = opts.from;
  return state;
};

homeConnectionMode.clickAnywhere = function (state, e) {
  if (
    (state.currentVertexPosition > 0 &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition - 1],
      )) ||
    (state.direction === 'backwards' &&
      isEventAtCoordinates(
        e,
        state.line.coordinates[state.currentVertexPosition + 1],
      ))
  ) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD });

  let lng = e.lngLat.lng;
  let lat = e.lngLat.lat;

  const snapVertexCoords = state.snapPoint.geometry.coordinates;

  // break if first coord is not snapped to connecting point
  if (state.currentVertexPosition === 0 && !snapVertexCoords.length) return;

  if (snapVertexCoords.length) {
    [lng, lat] = snapVertexCoords;
    // Set prop in line segment to connecting point
    if (state.snapPoint.snapFrom) {
      const point = this.getFeature(state.snapPoint.fromId);
      const prop = point.properties[state.connectFrom.copyProp.from];
      state.line.setProperty(state.connectFrom.copyProp.target, prop);
    }
  }

  state.line.updateCoordinate(state.currentVertexPosition, lng, lat);

  if (state.direction === 'forward') {
    state.currentVertexPosition++;
    state.line.updateCoordinate(state.currentVertexPosition, lng, lat);
  } else {
    state.line.addCoordinate(0, e.lngLat.lng, e.lngLat.lat);
  }
  // End draw if connected to network
  if (state.snapPoint.snapTo) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, {
      featureIds: [state.line.id],
    });
  }
};

homeConnectionMode.onMouseMove = function (state, e) {
  const mousePointer = {
    type: 'Point',
    coordinates: Object.values(e.lngLat),
  };
  const { line } = state;
  const { snapPoint } = state;
  const { coordinates } = line;
  snapPoint.geometry.coordinates = [];
  snapPoint.snapTo = false;
  snapPoint.snapFrom = false;

  const updateCoordinate = (lng, lat) => {
    line.updateCoordinate(state.currentVertexPosition, lng, lat);
    if (CommonSelectors.isVertex(e)) {
      this.updateUIClasses({ mouse: Constants.cursors.POINTER });
    }
  };

  // first coordinate must always be connected with a home connection point coordinates.length === 1
  if (coordinates.length === 1) {
    const pointFeatures = this.map.queryRenderedFeatures({
      layers: state.connectFrom.layers,
      filter: state.connectFrom.filter,
    });

    // if no features are rendered on map
    if (pointFeatures.length === 0) {
      updateCoordinate(e.lngLat.lng, e.lngLat.lat);
      return;
    }

    const snap = nearestPoint(mousePointer, {
      type: 'FeatureCollection',
      features: pointFeatures,
    });

    if (
      snap.properties.distanceToPoint !== Infinity &&
      snap.properties.distanceToPoint < SNAP_RADIUS_KM.radius
    ) {
      const [lng, lat] = snap.geometry.coordinates;
      updateCoordinate(lng, lat);
      snapPoint.geometry.coordinates = [lng, lat];
      snapPoint.fromId = snap.properties.id;
      snapPoint.snapFrom = true;
    } else {
      updateCoordinate(e.lngLat.lng, e.lngLat.lat);
    }
    this.map.getSource(this.SNAP_VERTEX_LAYER_ID).setData(snapPoint);
  } else {
    const lineFeatures = this.map.queryRenderedFeatures({
      layers: ['gl-draw-line-deactive.cold'],
    });
    const lineToSnap = getSnapToFraction(lineFeatures, line);

    if (lineToSnap.geometry.coordinates.length === 0) {
      updateCoordinate(e.lngLat.lng, e.lngLat.lat);
    } else {
      const snap = nearestPointOnLine(lineToSnap, mousePointer);
      if (
        snap.properties.dist !== Infinity &&
        snap.properties.dist < SNAP_RADIUS_KM.radius
      ) {
        const [lng, lat] = snap.geometry.coordinates;
        updateCoordinate(lng, lat);
        snapPoint.geometry.coordinates = [lng, lat];
        snapPoint.snapTo = true;
      } else {
        updateCoordinate(e.lngLat.lng, e.lngLat.lat);
      }
      this.map.getSource(this.SNAP_VERTEX_LAYER_ID).setData(snapPoint);
    }
  }
};

homeConnectionMode.onKeyUp = function (state, e) {
  clearSnapCoord(this.map, this.SNAP_VERTEX_LAYER_ID);
  if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

homeConnectionMode.onStop = function (state) {
  clearSnapCoord(this.map, this.SNAP_VERTEX_LAYER_ID);
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.line.id) === undefined) return;

  //remove last added coordinate
  state.line.removeCoordinate(`${state.currentVertexPosition}`);
  if (state.line.isValid()) {
    // Set point to connected
    this._ctx.api.setFeatureProperty(
      state.snapPoint.fromId,
      state.connectFrom.setProp.key,
      state.connectFrom.setProp.value,
    );
    for (const [key, value] of Object.entries(SET_PROPERTIES)) {
      state.line.setProperty(key, value);
    }
    state.line.setProperty('type', state.setType);
    this.map.fire(Constants.events.CREATE, {
      features: [state.line.toGeoJSON()],
    });
  } else {
    this.deleteFeature([state.line.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

homeConnectionMode.onTrash = function (state) {
  this.deleteFeature([state.line.id], { silent: true });
  clearSnapCoord(this.map, this.SNAP_VERTEX_LAYER_ID);
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

export default homeConnectionMode;

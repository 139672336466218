<template>
  <div class="absolute flex left-5 z-[100] h-10">
    <div class="w-[360px] flex gap-[5px]">
      <div class="flex standard-elevation-1 rounded-[8px]">
        <ToHome :class="{ 'rounded-r-none': !showMuScSelector }" />
        <transition name="fade-horizontal" mode="out-in">
          <ButtonEl
            v-if="!showMuScSelector"
            icon="pin_drop"
            variant="tertiary"
            class="rounded-l-none"
            icon-type="round"
            @click="toggleMuScSelector"
          />
        </transition>
      </div>
      <div class="flex standard-elevation-1 rounded-[8px]">
        <ButtonEl
          data-test="main-menu-toggle"
          icon="segment"
          :variant="toggleVariant('mainMenu')"
          icon-type="round"
          :class="{ 'rounded-r-none': isInfraModuleActive('projectPlaner') }"
          @click="toggleMainMenu"
        />
        <ButtonEl
          v-if="isInfraModuleActive('projectPlaner')"
          icon="account_tree"
          :variant="toggleVariant('projectPlaner')"
          class="rounded-l-none border-l border-l-line-disabled-neutral"
          data-test="project-areas"
          @click="toggleProjectMenu"
        />
      </div>

      <SearchBar />

      <MapActionsDialog />
    </div>
    <MapActions data-test="map-action" />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { isInfraModuleActive } from '@/utils/is-infra-module-active';
import ToHome from '@/features/map/control-options/components/ToHome.vue';
import ButtonEl from '@/components/storybook/src/stories/button/ButtonEl.vue';
import SearchBar from '@/features/map/control-options/search/SearchBar.vue';
import MapActionsDialog from '@/features/map/control-options/components/MapActionsDialog.vue';
import MapActions from '@/features/map/map-actions/MapActions.vue';

const store = useStore();

onMounted(() => {
  if (store.getters.getMainMenuShow) {
    store.commit('SET_MAIN_MENU_SHOW', false);
  }
});

const showMuScSelector = computed(() => store.getters.getMuScSelector);
const getMainMenuShow = computed(() => store.getters.getMainMenuShow);
const getProjectMenuShow = computed(() => store.getters.getProjectMenuShow);

function toggleMuScSelector() {
  store.commit('TOGGLE_MU_SC_SELECTOR', true);
}

function toggleMainMenu() {
  if (store.getters.getProjectMenuShow) {
    store.commit('SET_PROJECT_MENU_SHOW', false);
    return;
  }

  store.commit('SET_MAIN_MENU_SHOW', !store.getters.getMainMenuShow);
}

function toggleProjectMenu() {
  if (getProjectMenuShow.value) {
    store.commit('SET_MAIN_MENU_SHOW', false);
    store.commit('SET_PROJECT_MENU_SHOW', !getProjectMenuShow.value);
    isMainActive.value = false;
    isProjectActive.value = false;
    return;
  } else if (!getMainMenuShow.value) {
    store.commit('SET_MAIN_MENU_SHOW', true);
    isMainActive.value = true;
  }
  store.commit('SET_PROJECT_MENU_SHOW', !getProjectMenuShow.value);
  isProjectActive.value = !isProjectActive.value;
  if (isProjectActive.value) {
    isMainActive.value = false;
  }
}

const isMainActive = ref(false);
const isProjectActive = ref(false);

function toggleVariant(type) {
  if (type === 'mainMenu') {
    return getProjectMenuShow.value
      ? 'tertiary'
      : getMainMenuShow.value
        ? 'primary'
        : 'tertiary';
  } else if (type === 'projectPlaner') {
    return getProjectMenuShow.value ? 'primary' : 'tertiary';
  }
}
</script>

<style scoped lang="scss">
.fade-horizontal-enter-active,
.fade-horizontal-leave-active {
  transition: all 0.5s ease;
  overflow: hidden;
}

.fade-horizontal-enter-from,
.fade-horizontal-leave-to {
  width: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fade-horizontal-enter-to,
.fade-horizontal-leave-from {
  width: 40px;
  padding-left: initial !important;
  padding-right: initial !important;
}
</style>

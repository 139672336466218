import { modes } from '@/features/draw/constants';
import {
  buildingsWithin,
  blocksWithin,
  drawBoxSelect,
  drawDistributionNetwork,
  drawHomeConnection,
  drawCutLines,
  drawHeatConnection,
  drawAutoHomeConnect,
  applyPropertiesToSelectedFeatures,
} from '@/features/draw/draw';

export default class MapActionsData {
  constructor() {
    this.actions = [
      {
        processId: 'heatProject',
        items: [
          [
            {
              icon: 'IconLineNodes',
              drawMode: modes.DRAW_DISTRIBUTION_NETWORK,
              tooltipText: 'Verteilnetzleitung zeichnen',
              offset: [-122, -66],
              iconClasses: 'rotate-45',
              action: drawDistributionNetwork,
              isEditMode: true,
            },
            {
              icon: 'IconHouseLineFilled',
              drawMode: modes.DRAW_CONNECT_HOME,
              tooltipText: 'Hausanschlussleitung zeichnen',
              offset: [-136, -66],
              action: drawHomeConnection,
              isEditMode: true,
            },
            {
              icon: 'IconHeatWavesLineFilled',
              drawMode: modes.DRAW_CONNECT_HEAT_SOURCE,
              tooltipText: 'Leitung zur Wärmequelle zeichnen',
              offset: [-148, -66],
              action: drawHeatConnection,
              isEditMode: true,
            },
            {
              custom: true,
              id: 'pipeSettings',
              isEditMode: true,
            },
          ],
          [
            {
              icon: 'IconHousePointFilled',
              drawMode: null,
              tooltipText: 'Flurstücke automatisch anschließen',
              offset: [-152, -66],
              action: drawAutoHomeConnect,
              isEditMode: true,
            },
          ],
          [
            {
              icon: 'select_all',
              drawMode: null,
              tooltipText: 'Auswahlwerkzeug',
              offset: [-90, -66],
              action: drawBoxSelect,
              isEditMode: true,
            },
            {
              icon: 'content_cut',
              drawMode: modes.DRAW_CUT_LINES,
              tooltipText: 'Leitung trennen',
              offset: [-81, -66],
              action: drawCutLines,
              isEditMode: true,
            },
          ],
          [
            {
              id: 'heatProject',
              icon: 'select_all',
              drawMode: null,
              tooltipText: 'Gebäude auswählen',
              offset: [-100, -66],
              action: buildingsWithin,
              isEditMode: false,
            },
          ],
          [
            {
              custom: true,
              id: 'radiusSettings',
              isEditMode: true,
            },
          ],
        ],
      },
      {
        processId: 'AssignPriorityAreaToBlock',
        items: [
          [
            {
              id: 'AssignPriorityAreaToBlock',
              icon: 'select_all',
              drawMode: null,
              tooltipText: 'Blöcke auswählen',
              offset: [-90, -66],
              action: blocksWithin,
            },
          ],
        ],
      },
      {
        processId: 'measure',
        items: [
          [
            {
              custom: true,
              id: 'measure',
            },
          ],
        ],
      },
    ];
    this.showPipeSettings = false;
    this.showRadiusSettings = false;
  }

  togglePipeSettings() {
    this.showPipeSettings = !this.showPipeSettings;
  }

  toggleRadiusSettings() {
    this.showRadiusSettings = !this.showRadiusSettings;
  }
}

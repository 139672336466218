import MapboxDraw from '@mapbox/mapbox-gl-draw';
import FreehandMode from 'mapbox-gl-draw-freehand-mode';
import drawLayerStyle from '@/features/draw/drawLayerStyle';
import { getZoom } from '@/mapbox/main';
import simplify from '@turf/simplify';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import distributionNetwork from '@/features/draw/distribution-network';
import connectPoint from '@/features/draw/connect-point-mode';
import cutLinesMode from '@/features/draw/cut-lines-mode';
import measurePoints from '@/features/draw/measure-points-mode';
import measureArea from '@/features/draw/measure-area-mode';
import SimpleSelectCustom from '@/features/draw/simple-select-custom';
import autoHomeConnect from '@/features/draw/auto-home-connect';
import { LAYERS } from '@/features/heat-project/constants';
import FeaturesWithin from '@/features/draw/features-within';
import {
  modes,
  BUILDINGS_WITHIN_HEATPROJECT,
  BLOCKS_WITHIN_PRIORITY_AREA,
} from '@/features/draw/constants';

FreehandMode.simplify = function (polygon) {
  simplify(polygon, {
    mutate: true,
    tolerance: 1 / Math.pow(2, getZoom()),
    highQuality: true,
  });
};

export const draw = new MapboxDraw({
  userProperties: true,
  displayControlsDefault: false,
  controls: {
    polygon: true,
    line_string: true,
    point: true,
    trash: true,
  },
  styles: drawLayerStyle,
  modes: Object.assign(MapboxDraw.modes, {
    draw_polygon: FreehandMode,
    [modes.DRAW_FEATURES_WITHIN]: FeaturesWithin,
    simple_select: SimpleSelectCustom,
    direct_select: SimpleSelectCustom,
    [modes.DRAW_DISTRIBUTION_NETWORK]: distributionNetwork,
    [modes.DRAW_CONNECT_POINT]: connectPoint,
    [modes.DRAW_CUT_LINES]: cutLinesMode,
    [modes.AUTO_CONNECT_HOMES]: autoHomeConnect,
    [modes.DRAW_MEASURE_POINTS]: measurePoints,
    [modes.DRAW_MEASURE_AREA]: measureArea,
  }),
});

export function addDraw(map) {
  map.addControl(draw, 'top-right');
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon',
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash',
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line',
  ).style.display = 'none';
  document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point',
  ).style.display = 'none';
}

export function deleteFeatures() {
  draw.deleteAll();
}

export function drawPolygon() {
  document
    .querySelector('.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon')
    .click();
}

export function exitDraw() {
  const trashButton = document.querySelector(
    '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_trash',
  );
  if (trashButton) trashButton.click();
}

export function buildingsWithin() {
  draw.changeMode(modes.DRAW_FEATURES_WITHIN, {
    mode: modes.DRAW_FEATURES_WITHIN,
    actionType: BUILDINGS_WITHIN_HEATPROJECT,
  });
}

export function blocksWithin() {
  draw.changeMode(modes.DRAW_FEATURES_WITHIN, {
    mode: modes.DRAW_FEATURES_WITHIN,
    actionType: BLOCKS_WITHIN_PRIORITY_AREA,
  });
}

export function applyPropertiesToSelectedFeatures(properties) {
  const { features } = draw.getSelected();
  for (const feature of features) {
    for (const [key, value] of Object.entries(properties)) {
      draw.setFeatureProperty(feature.id, `${key}`, value);
    }
  }
}

export function drawDistributionNetwork() {
  draw.changeMode(modes.DRAW_DISTRIBUTION_NETWORK);
}

export function drawHomeConnection() {
  draw.changeMode(modes.DRAW_CONNECT_POINT, {
    from: {
      layers: ['gl-draw-point-home.cold'],
      filter: ['==', ['get', 'user_isConnected'], false],
      copyProp: { from: 'estate_id', target: 'estate_id' },
      setProp: { key: 'isConnected', value: true },
    },
    setType: LAYERS.HOME_CONNECTION,
    mode: modes.DRAW_CONNECT_HOME,
  });
}

export function drawHeatConnection() {
  draw.changeMode(modes.DRAW_CONNECT_POINT, {
    from: {
      layers: ['gl-draw-point-heat-source.cold'],
      filter: ['==', ['get', 'user_isConnected'], false],
      copyProp: { from: 'id', target: 'heat_source_id' },
      setProp: { key: 'isConnected', value: true },
    },
    setType: LAYERS.HEAT_SOURCE_CONNECTION,
    mode: modes.DRAW_CONNECT_HEAT_SOURCE,
  });
}

export function drawCutLines() {
  draw.changeMode(modes.DRAW_CUT_LINES, {
    toCut: {
      layers: ['gl-draw-line-deactive.cold'],
      filter: ['==', ['get', 'user_type'], LAYERS.DISTRIBUTION_NETWORK],
    },
  });
}

export function drawAutoHomeConnect() {
  draw.changeMode(modes.AUTO_CONNECT_HOMES);
  // workaround manually set to simple_select as it cant be set in
  // onSetup method without bug
  draw.changeMode('simple_select');
}

export function drawBoxSelect() {
  draw.changeMode('draw_polygon');
  draw.changeMode('simple_select', { startWithBoxSelect: true });
}

export function drawMeasurePoints() {
  draw.changeMode(modes.DRAW_MEASURE_POINTS);
}

export function drawMeasureArea() {
  draw.changeMode(modes.DRAW_MEASURE_AREA);
}
